import React from "react"
import { Link } from "gatsby-link"
import { useLocation } from "@reach/router"
import { Box, ExternalLink } from "@mallardbay/lib-react-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF"
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons/faLinkedin"
import { faYoutube } from "@fortawesome/free-brands-svg-icons/faYoutube"
import { faHeart } from "@fortawesome/free-solid-svg-icons/faHeart"

import ResponsiveBlock from "~components/shared/responsive-block"
import Divider from "~components/shared/divider"
import TextLogo from "~components/shared/logo/text-logo"
import DotSeparator from "~components/shared/dot-separator"
import { GUIDE_TECH_URL, SOCIAL_LINKS } from "~config/constants"
import packageJson from "~package"
import COPY from "~config/copy-constants"
import SupportLink, { TYPES } from "~components/shared/support-link"
import { NAVIGATION_URLS } from "~utils/navigation-helpers"
import { useColors } from "~components/shared/todo-lib-react-components/hooks/use-colors"
import { useGlobalNavigation } from "~config/global-navigation-provider"

import Span from "./todo-lib-react-components/span"
import { useIsMobile } from "./todo-lib-react-components/hooks/use-is-mobile"

export default function Footer() {
    const styles = useStyles()
    const shouldRender = useShouldRenderFooter()

    if (!shouldRender) return null

    const linkConfigs = getLinkConfigs()
    const socialLinkConfigs = getSocialLinkConfigs()

    const copyrightText = getCopyrightText()

    return (
        <ResponsiveBlock style={styles.container}>
            <Box style={styles.top}>
                <Box style={styles.topLogoContainer}>
                    <TextLogo isCentered />
                    <Box style={styles.titleTop}>{COPY.FOOTER_COPY}</Box>

                    <Box style={styles.supportLinksContainer}>
                        <h3>{COPY.CONTACT_US}</h3>

                        <SupportLink type={TYPES.PHONE} />
                        <SupportLink type={TYPES.EMAIL} />
                    </Box>
                </Box>

                <Box style={styles.topLinkContainer}>
                    {linkConfigs.map(({ to, label, isExternal }) =>
                        isExternal ? (
                            <ExternalLink key={label} href={to}>
                                <Box style={styles.topLink}>{label}</Box>
                            </ExternalLink>
                        ) : (
                            <Box key={label} style={styles.topLink}>
                                <Link style={styles.link} to={to}>
                                    {label}
                                </Link>
                            </Box>
                        )
                    )}
                </Box>
            </Box>
            <Divider />
            <Box style={styles.bottom}>
                <Box style={styles.bottomContainer}>
                    <Box style={styles.titleBottom}>{copyrightText}</Box>
                    <Span style={styles.dotSeparator}>
                        <DotSeparator />
                    </Span>
                    <Box style={styles.heartSection}>
                        {COPY.MADE_WITH}
                        <FontAwesomeIcon
                            style={styles.heartIcon}
                            icon={faHeart}
                        />
                        {COPY.IN_BATON_ROUGE}
                    </Box>
                    <Span style={styles.dotSeparator}>
                        <DotSeparator />
                    </Span>
                    <Box style={styles.heartSection}>
                        v{packageJson.version}
                    </Box>
                </Box>
                <Box style={styles.iconContainer}>
                    {socialLinkConfigs.map(({ to, icon }) => (
                        <ExternalLink key={to} href={to}>
                            <FontAwesomeIcon
                                style={styles.socialIcon}
                                icon={icon}
                            />
                        </ExternalLink>
                    ))}
                </Box>
            </Box>
        </ResponsiveBlock>
    )
}

function useShouldRenderFooter() {
    const { pathname } = useLocation()
    const { isFooterHidden } = useGlobalNavigation()
    if (isFooterHidden) return false

    return ["/booking", "/wishlists", "/inbox"].every(
        (path) => !pathname.includes(path)
    )
}

function getCopyrightText() {
    const year = new Date().getFullYear()

    return `${COPY.COPYRIGHT_CHAR} ${year} ${COPY.MALLARD_BAY_INC}`
}

function getLinkConfigs() {
    return [
        {
            to: "/blog",
            label: COPY.ARTICLES,
            isExternal: false,
        },
        {
            to: "/about",
            label: COPY.ABOUT_US,
            isExternal: false,
        },
        {
            to: "/press",
            label: COPY.MALLARD_BAY_NEWS,
            isExternal: false,
        },
        {
            to: NAVIGATION_URLS.INTERNAL.HELP,
            label: COPY.HELP_AND_SUPPORT,
            isExternal: false,
        },
        {
            to: GUIDE_TECH_URL,
            label: COPY.LIST_ON_MALLARD_BAY,
            isExternal: true,
        },
        {
            to: GUIDE_TECH_URL,
            label: COPY.DEMO_GUIDETECH,
            isExternal: true,
        },
        {
            to: "/privacy",
            label: COPY.PRIVACY,
            isExternal: true,
        },
        {
            to: "/terms",
            label: COPY.TERMS,
            isExternal: true,
        },
        {
            to: NAVIGATION_URLS.INTERNAL.SITEMAP,
            label: COPY.SITEMAP,
            isExternal: false,
        },
    ]
}

function getSocialLinkConfigs() {
    return [
        {
            to: SOCIAL_LINKS.FACEBOOK,
            icon: faFacebookF,
        },
        {
            to: SOCIAL_LINKS.INSTAGRAM,
            icon: faInstagram,
        },
        {
            to: SOCIAL_LINKS.YOUTUBE,
            icon: faYoutube,
        },
        {
            to: SOCIAL_LINKS.LINKEDIN,
            icon: faLinkedin,
        },
    ]
}

function useStyles() {
    const colors = useColors()
    const isMobile = useIsMobile()

    return {
        container: {
            backgroundColor: colors.backgroundSecondary,
            borderTop: `1px solid ${colors.borderColor}`,
            paddingBottom: { sm: 0 },
        },
        footer: {
            backgroundColor: colors.backgroundSecondary,
            borderTop: `1px solid ${colors.borderOpaque}`,
            paddingBottom: { sm: 0 },
        },
        top: {
            textAlign: "center",
            padding: { base: "40px 0", md: "64px 0" },
        },
        titleTop: {
            padding: { base: "16px 0", md: "20px 0 10px 0" },
            color: colors.contentPrimary,
            fontSize: { md: "16px" },
        },
        topLogoContainer: {
            paddingTop: { md: "10px" },
        },
        supportLinksContainer: {
            marginTop: "30px",
            marginBottom: "40px",
            lineHeight: "2",
        },
        topLinkContainer: {
            display: "grid",
            flexDirection: "column",
            justifyContent: "flex-start",
            flexWrap: "wrap",
            fontSize: { base: "16px", md: "18px" },
            fontWeight: "bold",
            gridTemplateColumns: {
                base: "repeat(2, 1fr)",
                md: "repeat(4, 1fr)",
            },
        },
        topLink: {
            fontWeight: "bold",
            margin: { base: "8px", md: "12px 16px 0 16px" },
            textAlign: { base: "left", md: "center" },
            minWidth: "max-content",
            color: colors.contentPrimary,
        },
        link: {
            color: "inherit",
            textDecoration: "none",
        },
        bottom: {
            display: "flex",
            flexDirection: { base: "column", md: "row" },
            textAlign: { base: "center", md: "left" },
            alignItems: "center",
            justifyContent: "space-between",
            paddingTop: "24px",
            paddingRight: 0,
            paddingBottom: "24px",
            paddingLeft: 0,
            color: colors.contentPrimary,
            fontSize: "14px",
            fontWeight: 300,
        },
        bottomContainer: {
            display: "flex",
            flexDirection: { base: "column", md: "row" },
        },
        titleBottom: {
            marginBottom: { base: "8px", md: 0 },
        },
        heartSection: {
            display: "flex",
            justifyContent: "center",
        },
        heartIcon: {
            fontSize: "14px",
            marginTop: "0px",
            marginRight: "6px",
            marginBottom: "0px",
            marginLeft: "6px",
            color: "rgb(246, 94, 56)",
        },
        socialIcon: {
            fontSize: "18px",
            margin: isMobile ? "16px 8px 0 8px" : "0 0 0 24px",
            color: colors.contentPrimary,
        },
        dotSeparator: {
            display: { base: "none", md: "initial" },
        },
        iconContainer: {
            display: "flex",
            marginRight: "70px", // Allow space for the support bubble
        },
    }
}
