import React from "react"
import {
    Button,
    componentStyles,
    Image,
} from "@mallardbay/lib-react-components"
import { faHeart } from "@fortawesome/free-solid-svg-icons/faHeart"

import type { ListingMinimalFieldsFragment } from "~graphql/generated/graphql"
import { useUpdateWishlistMutation } from "~graphql/generated/graphql"
import { useToastWithAction } from "~components/shared/todo-lib-react-components/hooks/use-toast"
import { navigateToWishlist } from "~utils/navigation-helpers"
import COPY from "~config/copy-constants"
import Box from "~components/shared/todo-lib-react-components/box"
import { useColors } from "~components/shared/todo-lib-react-components/hooks/use-colors"
import { getListingsCountLabel } from "~utils/helpers"

interface Props {
    readonly listingId: string
    readonly wishlistId: string
    readonly name: string
    readonly listings: ListingMinimalFieldsFragment[]
    readonly setIsOpen: (value: boolean) => void
}

export default function SaveModalLink({
    listingId,
    wishlistId,
    name,
    listings,
    setIsOpen,
}: Props) {
    const styles = useStyles()
    const showToast = useAddToWishlistToast({ name, wishlistId })

    const [updateWishlist, { loading: isUpdating }] = useUpdateWishlistMutation(
        {
            variables: {
                id: wishlistId,
                data: {
                    listings: listings.some(
                        (listing) => listing.id === listingId
                    )
                        ? [...listings.map((listing) => listing.id)]
                        : [...listings.map((listing) => listing.id), listingId],
                },
            },
            onCompleted: () => {
                setIsOpen(false)
                showToast()
            },
        }
    )

    return (
        <Button
            isLoading={isUpdating}
            style={styles.root}
            onClick={() => updateWishlist()}
        >
            <Box style={styles.imageContainer}>
                <Box style={styles.imageWrapper}>
                    <Image
                        src={listings[0]?.photos?.[0]?.url}
                        style={styles.image}
                    />
                </Box>
                <Box style={styles.content}>
                    <Box style={styles.name}>{name}</Box>
                    <Box style={styles.size}>
                        {getListingsCountLabel(listings.length)}
                    </Box>
                </Box>
            </Box>
        </Button>
    )
}

function useStyles() {
    const colors = useColors()

    return componentStyles({
        root: {
            maxHeight: "106px",
            minHeight: "106px",
            backgroundColor: colors.bgColor,
            borderRadius: "8px",
            transitionProperty: "all",
            transitionDuration: "200ms",
            display: "flex",
            alignItems: "center",
            width: "90%",
            margin: "0.5rem 0.8rem",
        },
        imageWrapper: {
            width: "64px",
            height: "4rem",
        },
        image: {
            height: "100%",
            width: "100%",
            borderRadius: "8px",
        },
        content: {
            padding: 3.5,
            textAlign: "left",
        },
        name: {
            fontSize: "16px",
            fontWeight: 700,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            marginBottom: 2,
        },
        size: {
            fontSize: "14px",
            fontWeight: 400,
        },
        imageContainer: {
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
        },
    })
}

function useAddToWishlistToast({
    name,
    wishlistId,
}: {
    name?: string
    wishlistId: string
}) {
    return useToastWithAction({
        message: `${COPY.ADDED_TO} ${name}`,
        actionLabel: COPY.VIEW_LIST,
        onActionClick: () => navigateToWishlist(wishlistId),
        icon: faHeart,
        iconColor: "red",
    })
}
