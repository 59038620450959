import React from "react"
import { Select as SelectOg } from "@chakra-ui/react"
import type { StyleProps } from "@mallardbay/lib-react-components"
import { componentStyles } from "@mallardbay/lib-react-components"

import { useColors } from "~components/shared/todo-lib-react-components/hooks/use-colors"
import { isNil } from "~utils/helpers"

interface SelectOption<ValueType extends string> {
    label: string
    value: ValueType
}

interface Props<ValueType extends string> {
    readonly testId?: string
    readonly style?: StyleProps
    readonly value: ValueType
    readonly onChange: (value: ValueType) => void
    readonly options: SelectOption<ValueType>[]
    readonly isRequired?: boolean
}

export default function Select<ValueType extends string>({
    testId,
    style,
    value,
    onChange,
    options,
    isRequired,
}: Props<ValueType>) {
    const styles = useStyles()

    function handleChange(event: React.ChangeEvent<HTMLSelectElement>) {
        const newValue = event.target.value

        if (isValueType<ValueType>(newValue)) {
            onChange(newValue)
        }
    }

    return (
        <SelectOg
            data-testid={testId}
            sx={{ ...styles.root, ...style }}
            value={value}
            onChange={handleChange}
            size="lg"
            isRequired={isRequired}
        >
            {options.map((option) => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </SelectOg>
    )
}

function isValueType<ValueType extends string>(
    value: string | null | undefined
): value is ValueType {
    return !isNil(value)
}

function useStyles() {
    const colors = useColors()

    return componentStyles({
        root: {
            backgroundColor: colors.backgroundSecondary,
            fontSize: "md",
        },
    })
}
